<template>
  <div class="SectionForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="版块信息">
          <el-form-item v-if="regionVisible" label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
            <v-select2 v-model="form.regionId" placeholder="输入公司名称" v-bind="regionParams" :width="width" />
          </el-form-item>
          <el-form-item label="版块名称" :rules="[{ required: true, message: '请输入版块名称', trigger: 'change' }]" prop="sectionName">
            <v-input v-model="form.sectionName" placeholder="请输入版块名称" :maxlength="10" :width="width" />
          </el-form-item>
          <div v-if="sectionLevelVisible">
            <el-form-item label="版块层级" :rules="[{ required: true, message: '请选择版块层级', trigger: 'change' }]" prop="sectionLevel">
              <v-select v-model="form.sectionLevel" :options="sectionLevelOps" :disabled="disabled" :width="width" @change="sectionLevelChange" />
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="版块层级">
              {{ sectionLevelMap[form.sectionLevel] }}
            </el-form-item>
          </div>
          <el-form-item v-if="sectionParentVisible" label="上级版块" :rules="[{ required: true, message: '请选择上级版块', trigger: 'change' }]" prop="sectionParentid">
            <v-select v-model="form.sectionParentid" :options="sectionParentOps" :disabled="disabled" :width="width" />
          </el-form-item>
          <div v-if="sectionTypeVisible">
            <el-form-item label="版块类型" :rules="[{ required: true, message: '请选择版块类型', trigger: 'change' }]" prop="sectionType">
              <v-select v-model="form.sectionType" :options="sectionTypeOps" :width="width" />
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="版块类型">
              {{ sectionTypeMap[form.sectionType] }}
            </el-form-item>
          </div>
          <el-form-item label="版块排序" :rules="[{ required: true, message: '请输入版块排序', trigger: 'change' }]" prop="sectionSort">
            <v-input-number v-model="form.sectionSort" placeholder="请输入版块排序" :max="999" controls controlsPosition="right" :width="width" />
            <label class="tips">注：数值越小，排在越前面</label>
          </el-form-item>
          <el-form-item label="版块状态" :rules="[{ required: true, message: '请选择版块状态', trigger: 'change' }]" prop="visible">
            <v-select v-model="form.visible" :options="visibleOps" :disabled="disabled" :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import { regionParams } from 'common/select2Params'
import { sectionLevelMap, setSectionLevelOps, sectionTypeMap, setSectionTypeOps, setVisibleOps } from '../map'
import { getSectionURL } from '../api'
import { createURL, editURL, queryURL } from './api'

let visibleBefore = undefined

export default {
  name: 'SectionForm',
  components: {
    Col2Detail,
    Col2Block
  },
  props: {
    type: String
  },
  data () {
    return {
      regionVisible: false,
      width: 182,
      sectionLevelOps: setSectionLevelOps(),
      sectionParentOps: [],
      sectionTypeOps: [],
      visibleOps: setVisibleOps(),
      sectionLevelVisible: true,
      sectionParentVisible: false,
      sectionTypeVisible: true,
      sectionLevelMap,
      sectionTypeMap,
      disabled: false,
      regionParams,
      sectionParentName: undefined,
      form: {
        id: undefined,
        regionId: undefined,
        sectionName: '',
        sectionLevel: undefined,
        sectionParentid: undefined,
        sectionType: undefined,
        sectionSort: 0,
        visible: undefined
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: '',
        submitMethod: ''
      }
    }
  },
  created () {
    if (this.type === 'platform') {
      this.getSection({
        sectionLevel: 1
      })
    } else if (this.type === 'tenant') {
      const userType = this.$store.state.userInfo.userType
      if (['106', '100'].includes(userType)) {
        this.regionVisible = true
      } else if (userType === '102') {
        this.getSection({
          sectionLevel: 1
        })
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.queryUrl = `${queryURL}/${id}`
      this.submitConfig.submitUrl = editURL
      this.submitConfig.submitMethod = 'PUT'
      this.$refs.formPanel.getData()
      this.sectionTypeOps = setSectionTypeOps()
      this.sectionLevelVisible = false
      this.sectionTypeVisible = false
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
      // 新增版块时, 版块类型只能选择自定义选项
      this.sectionTypeOps = setSectionTypeOps().splice(0, 1)
      // 设置下拉选择框的默认选项
      this.form.sectionLevel = this.sectionLevelOps[0].value
      this.form.sectionType = this.sectionTypeOps[0].value
      this.form.visible = this.visibleOps[0].value
    }
  },
  watch: {
    'form.regionId' (regionId) {
      if (regionId) {
        this.getSection({
          sectionLevel: 1,
          regionId
        })
      } else {
        this.sectionParentOps = []
      }
      this.form.sectionParentid = undefined
    }
  },
  methods: {
    async getSection (params) {
      const { status, data } = await this.$axios.get(getSectionURL, { params })
      if (status === 100) {
        const sectionParentOps = []
        if (data && data.length) {
          data.forEach(item => {
            sectionParentOps.push({
              text: item.sectionName,
              value: item.id
            })
          })
        }
        this.sectionParentOps = sectionParentOps
        // 上级版块
        if (this.sectionParentName) {
          const sectionParentid = this.form.sectionParentid
          if (sectionParentid) {
            let firstLevelIndex = this.sectionParentOps.findIndex((sectionOp) => {
              return sectionOp.value === sectionParentid
            })
            if (firstLevelIndex === -1) {
              this.sectionParentOps.unshift({
                text: this.sectionParentName,
                value: sectionParentid
              })
            }
          }
          this.sectionParentName = undefined
        }
      }
    },
    sectionLevelChange (sectionLevel) {
      if (sectionLevel === 2) {
        this.sectionParentVisible = true
      } else {
        this.sectionParentVisible = false
      }
    },
    update (data) {
      visibleBefore = data['visible']
      const sectionType = data['sectionType']
      if (sectionType === 1) {
        this.disabled = true
      }
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined && data[key] !== '') {
          if (key === 'sectionParentid') {
            this.$nextTick(() => {
              this.form[key] = data[key]
            }, 20)
          } else {
            this.form[key] = data[key]
          }
        }
      })
      const sectionLevel = data['sectionLevel']
      this.sectionLevelChange(sectionLevel)
      this.sectionParentName = data['sectionParentName']
    },
    async submitBefore (data) {
      // 租户版块--版块状态由正常改为关闭，需要提示用户是否继续操作
      if (this.type === 'tenant') {
        if (visibleBefore === 1 && this.form.visible === 0) {
          let confirmText = '关闭版块后，在APP中将不再显示版块及下属应用，是否关闭？'
          let result = await this.$confirm(confirmText)
          if (!result) {
            return false
          }
        }
      }
      if (!this.sectionParentVisible) {
        data['sectionParentid'] = undefined
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.SectionForm-wrapper {
  height: 100%;
  .tips {
    margin-left: 20px;
  }
}
</style>
