import {
  mapHelper
} from 'common/utils'

// 所属类别
const subHandle = [{
    text: '项目应用',
    value: 0
  },
  {
    text: '电商应用',
    value: 1
  }
]

// 应用形式
const appTypeOps = [
  {
    text: '普通应用',
    value: 0,
  },
  {
    text: '工单应用',
    value: 1,
  }
]

const {
  map: appTypeMap,
  setOps: appTypeHandleOps
} = mapHelper.setMap(appTypeOps)

const {
  map: subHandleMap,
  setOps: setSubHandleOps
} = mapHelper.setMap(subHandle)

// 应用类型
const subType = [{
    text: '原生功能入口',
    value: 8
  },
  {
    text: 'H5页面入口',
    value: 7
  },
  {
    text: '电商商家入口',
    value: 2
  },
  {
    text: '商品入口',
    value: 11
  },
  {
    text: '商品标签入口',
    value: 13
  }
]

const {
  map: subTypeMap,
  setOps: setSubTypeOps
} = mapHelper.setMap(subType)

// 状态
const status = [{
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 物管授权
const manageOauth = [{
    text: '授权',
    value: 1
  },
  {
    text: '不授权',
    value: 0
  }
]

const {
  map: manageOauthMap,
  setOps: setManageOauthOps
} = mapHelper.setMap(manageOauth)

// 话题类型
const subTopicType = [{
    text: '个人',
    value: 0
  },
  {
    text: '组织',
    value: 1
  }
]

const {
  map: subTopicTypeMap,
  setOps: setSubTopicTypeOps
} = mapHelper.setMap(subTopicType)

// 授权状态
const usedOauth = [{
    text: '需要授权',
    value: 1
  },
  {
    text: '无需授权',
    value: 0
  }
]

const {
  map: usedOauthMap,
  setOps: setUsedOauthOps
} = mapHelper.setMap(usedOauth)

// 应用状态
const subStatus = [{
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: subStatusMap,
  setOps: setSubStatusOps
} = mapHelper.setMap(subStatus)

// 是否高级授权
const oauthHighInterface = [{
    text: '允许',
    value: 1
  },
  {
    text: '不允许',
    value: 0
  }
]

const {
  map: oauthHighInterfaceMap,
  setOps: setOauthHighInterfaceOps
} = mapHelper.setMap(oauthHighInterface)

// 应用版块层级
const sectionLevel = [{
    text: '一级',
    value: 1
  },
  {
    text: '二级',
    value: 2
  }
]

const {
  map: sectionLevelMap,
  setOps: setSectionLevelOps
} = mapHelper.setMap(sectionLevel)

// 版块状态
const visible = [{
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

const {
  map: visibleMap,
  setOps: setVisibleOps
} = mapHelper.setMap(visible)

// 应用版块类型
const sectionType = [{
    text: '自定义',
    value: 0
  },
  {
    text: '业务联动',
    value: 1
  }
]

const {
  map: sectionTypeMap,
  setOps: setSectionTypeOps
} = mapHelper.setMap(sectionType)

// 暂停操作
const setPauseType = [{
    text: '应用暂停',
    value: 1
  },
  {
    text: '取消暂停',
    value: 2
  }
]

const {
  map: setPauseTypeMap,
  setOps: setPauseTypeOps
} = mapHelper.setMap(setPauseType)

// 是否开启服务时间
const isOpenTime = [{
    text: '关闭',
    value: 1
  },
  {
    text: '开启',
    value: 0
  }
]

const {
  map: isOpenTimeMap,
  setOps: setIsOpenTimeOps
} = mapHelper.setMap(isOpenTime)

// 是否支持售后
const isSupportAftersales = [{
    text: '关闭',
    value: 1
  },
  {
    text: '开启',
    value: 0
  }
]

const {
  map: isSupportAftersalesMap,
  setOps: setIsSupportAftersalesOps
} = mapHelper.setMap(isSupportAftersales)

// 项目状态
const communityStatus = [{
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)

// 应用归属
const isInternal = [{
    text: '内部应用',
    value: 1
  },
  {
    text: '外部应用',
    value: 0
  }
]

const {
  map: isInternalMap,
  setOps: setIsInternalOps
} = mapHelper.setMap(isInternal)

export {
  subHandleMap,
  setSubHandleOps,
  subTypeMap,
  setSubTypeOps,
  statusMap,
  setStatusOps,
  manageOauthMap,
  setManageOauthOps,
  subTopicTypeMap,
  setSubTopicTypeOps,
  usedOauthMap,
  setUsedOauthOps,
  subStatusMap,
  setSubStatusOps,
  oauthHighInterfaceMap,
  setOauthHighInterfaceOps,
  sectionLevelMap,
  setSectionLevelOps,
  visibleMap,
  setVisibleOps,
  sectionTypeMap,
  setSectionTypeOps,
  setPauseTypeMap,
  setPauseTypeOps,
  isOpenTimeMap,
  setIsOpenTimeOps,
  isSupportAftersalesMap,
  setIsSupportAftersalesOps,
  communityStatusMap,
  setCommunityStatusOps,
  isInternalMap,
  setIsInternalOps,
  appTypeMap,
  appTypeHandleOps,
}
