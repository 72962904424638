var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SectionForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "版块信息" } },
                [
                  _vm.regionVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属公司",
                            prop: "regionId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属公司",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "输入公司名称",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.regionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "regionId", $$v)
                                  },
                                  expression: "form.regionId",
                                },
                              },
                              "v-select2",
                              _vm.regionParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "版块名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入版块名称",
                            trigger: "change",
                          },
                        ],
                        prop: "sectionName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入版块名称",
                          maxlength: 10,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sectionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sectionName", $$v)
                          },
                          expression: "form.sectionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.sectionLevelVisible
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "版块层级",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择版块层级",
                                    trigger: "change",
                                  },
                                ],
                                prop: "sectionLevel",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.sectionLevelOps,
                                  disabled: _vm.disabled,
                                  width: _vm.width,
                                },
                                on: { change: _vm.sectionLevelChange },
                                model: {
                                  value: _vm.form.sectionLevel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sectionLevel", $$v)
                                  },
                                  expression: "form.sectionLevel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("el-form-item", { attrs: { label: "版块层级" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.sectionLevelMap[_vm.form.sectionLevel]
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                  _vm.sectionParentVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上级版块",
                            rules: [
                              {
                                required: true,
                                message: "请选择上级版块",
                                trigger: "change",
                              },
                            ],
                            prop: "sectionParentid",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.sectionParentOps,
                              disabled: _vm.disabled,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.sectionParentid,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sectionParentid", $$v)
                              },
                              expression: "form.sectionParentid",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.sectionTypeVisible
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "版块类型",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择版块类型",
                                    trigger: "change",
                                  },
                                ],
                                prop: "sectionType",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.sectionTypeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.sectionType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sectionType", $$v)
                                  },
                                  expression: "form.sectionType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("el-form-item", { attrs: { label: "版块类型" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.sectionTypeMap[_vm.form.sectionType]
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "版块排序",
                        rules: [
                          {
                            required: true,
                            message: "请输入版块排序",
                            trigger: "change",
                          },
                        ],
                        prop: "sectionSort",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入版块排序",
                          max: 999,
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sectionSort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sectionSort", $$v)
                          },
                          expression: "form.sectionSort",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("注：数值越小，排在越前面"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "版块状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择版块状态",
                            trigger: "change",
                          },
                        ],
                        prop: "visible",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.visibleOps,
                          disabled: _vm.disabled,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.visible,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "visible", $$v)
                          },
                          expression: "form.visible",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }